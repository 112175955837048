.projects-section, .client-work-section  {
    position: relative;
    height: fit-content;
    overflow: hidden;
    justify-content: flex-start;
}

.projects-section.single-page, .client-work-section.single-page {
    opacity: 0;
    animation: opacityAnimation 2s linear;
    animation-fill-mode: forwards;
}

.projects-section > .content-wrapper, .client-work-section > .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding-top: 4rem;
}

.projects-section > .content-wrapper, .client-work-section > .content-wrapper.left {
    padding-left: 3rem;
}

.client-work-section > .content-wrapper {
    padding-right: 3rem;
}

.client-work-section > .content-wrapper.left {
    padding-left: 3rem;
    padding-right: 0;
}

.project-card {
    display: flex;
    align-items: center;
}

.project-card.reverse {
    flex-direction: row-reverse;
}

.project-card > div {
    width: 50%;
}

.project-screenshot {
    width: 100%;
    border: 3px solid var(--primary-light);
    border-radius: 5px;
    box-shadow: 1rem 1rem var(--accent);
    transition: box-shadow 0.25s ease;
}

.project-screenshot:hover {
    box-shadow: 0.5rem 0.5rem var(--accent);
}

.project-card > .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}

.project-card > .right-wrapper {
    padding-left: 10%;
}

.project-card > .left-wrapper {
    padding-left: 0;
    padding-right: 10%;
    align-items: flex-start;
    text-align: left;
}

.project-card > .content-wrapper > h4 > a, .project-card > .content-wrapper > p > a {
    color: var(--accent);
}

.project-card > .content-wrapper > h4 > a:hover, .project-card > .content-wrapper > p > a:hover {
    color: white;
}

.project-card > .content-wrapper > h5 {
    color: var(--primary-light);
    margin-bottom: 1rem;
}

.project-card > .content-wrapper > p {
    width: 150%;
    background-color: var(--primary-dark);
    padding: 1rem;
    padding-left: 3rem;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.project-card > .left-wrapper > p {
    padding-left: 1rem;
    padding-right: 3rem;
}

.project-card > .content-wrapper > .project-tech-wrapper {
    display: flex;
}

.project-card > .content-wrapper > .project-tech-wrapper > * {
    padding: 0 0.5rem;
    border-left: 1px solid var(--accent);
    border-right: 1px solid var(--accent);
    margin-bottom: 1rem;
    white-space: nowrap;
}

.project-card > .content-wrapper > .project-tech-wrapper p:first-child {
    border-left: none;
}

.project-card > .content-wrapper > .project-tech-wrapper p:last-child {
    border-right: none;
}

.project-links-wrapper {
    display: flex;
    gap: 1rem;
}

.project-link-icon {
    fill: var(--accent);
    height: 1.5rem;
    width: 1.5rem;
    transition: height width 0.25s ease;
}

.project-link-icon:hover {
    fill: white;
}


/* ----- Mobile View ----- */
@media (max-width: 1024px) {
    .projects-section, .client-work-section  {
        gap: 2rem;
        text-align: center;
    }

    .projects-section {
        flex-direction: column;
    }

    .client-work-section {
        flex-direction: column-reverse;
    }

    .projects-section > .content-wrapper, .client-work-section > .content-wrapper, .client-work-section > .content-wrapper.left {
        padding-left: 0;
        padding-right: 0;
    }

    .project-card, .project-card.reverse {
        flex-direction: column;
    }

    .project-card > div {
        width: 100%;
    }

    .project-card > .content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }
    
    .project-card > .right-wrapper, .project-card > .left-wrapper {
        margin-top: 1rem;
        padding-left: 0;
        padding-right: 0;
    }

    .project-card > .content-wrapper > p {
        width: 100%;
        padding: 1rem;
    }

    .project-card > .content-wrapper > .project-tech-wrapper > * {
        font-size: 0.75rem;
    }
}