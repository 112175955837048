@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-darkest: rgb(23,28,40);
    --primary-dark: rgb(28,36,50);
    --primary-light: rgb(172, 181, 207);
    --accent: rgb(61, 210, 204);

    font-size: 16px;
}

* {
    color: white;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.75rem;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.hidden {
    display: none;
}

h1, h2, h3, h4, h5 {
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {font-size: 2.441rem;}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}

small {font-size: 0.8rem;}

p {
    color: var(--primary-light);
}

.color-light {
    color: var(--primary-light);
}

.color-accent {
    color: var(--accent);
}

.button-wrapper {
    color: var(--accent);
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    background-image: linear-gradient(90deg, var(--accent), var(--primary-darkest), var(--accent), var(--primary-darkest), var(--accent));
    background-size: 200% 200%;
}

.inner-button-wrapper {
    background-color: var(--primary-darkest);
    margin: 1px;
    padding: 0.5rem 0;
    border-radius: 5px;
}

.inner-button-wrapper:hover {
    background-color: var(--primary-dark);
}

.inner-button-wrapper > .nav-link {
    padding: 0.5rem 1rem;
}

.button-wrapper > .nav-link {
    color: var(--accent);
}

body {
    background-color: var(--primary-darkest);
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

#root {
    max-width: 1440px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page {
    flex-grow: 1;
    width: 100%;
    padding: 1rem;
}

section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5rem;
    margin-bottom: 8rem;
}

@keyframes spinningBackground {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 100%;
    }
}


/* ----- Mobile View ----- */
@media (max-width: 1024px) {
    section {
        padding: 0 1rem;
    }
}