header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
}

a {
    line-height: 0;
    text-decoration: none;
}

.site-logo {
    max-width: 300px;
}

.desktop-nav {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.responsive-nav {
    background-color: var(--primary-dark);
    position: absolute;
    top: 5rem;
    left: 0;
    width: 100%;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    overflow: hidden;
    transition: height 0.25s ease;
}

.responsive-nav.active-dropdown {
    height: 208px;
}

.nav-link {
    position: relative;
    color: var(--primary-light);
    font-size: 1rem;
}

.nav-link:hover {
    color: var(--accent);
}

.active-link {
    color: var(--accent);
}

.active-link::after {
    content: '';
    position: absolute;
    bottom: -0.75rem;
    left: 0;
    display: block;
    background-color: var(--accent);
    width: 100%;
    height: 2px;
}

.button-wrapper .active-link::after {
    background-color: transparent;
}

header .button-wrapper, footer .button-wrapper {
    animation: spinningBackground 2s linear;
    animation-delay: 0s;
    animation-iteration-count:infinite;
    animation-fill-mode: none;
}

.responsive-nav > .nav-link {
    width: 100%;
    text-align: center;
}

.responsive-nav > .active-link::after {
    display: none;
}

.menu-icon {
    fill: var(--primary-light);
    height: 1.5rem;
    width: 1.5rem;
}

.menu-icon:hover {
    fill: var(--accent);
}


/* ----- Tablet Styles ----- */
@media (max-width: 1024px) {
    .site-logo {
        max-width: 225px;
    }
}