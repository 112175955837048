.section-header-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: floatingAnimation 2s ease;
    animation-iteration-count: infinite;
}

.header-letter {
    font-size: 3rem;
    font-weight: normal;
    line-height: 2.5rem;
    background-color: var(--primary-darkest);
    z-index: 10;
}

.background-line {
    position: absolute;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: var(--accent);
}

@keyframes floatingAnimation {
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(0.5rem);
    }
    100%{
        transform: translateY(0);
    }
}


/* ----- Mobile View ----- */
@media (max-width: 1024px) {
    .section-header-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .background-line {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 2px;
    }
}