.tech-stack-section  {
    flex-direction: row;
    gap: 7.5rem;
    align-items: center;
}

.tech-stack-section .content-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr 1fr;
    gap: 5rem;
    margin: 4rem 0;
}

.tech-wrapper {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr auto;
    gap: 1rem;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    box-shadow: none;
    transition: box-shadow 0.25s ease;
}

.tech-wrapper:hover {
    box-shadow: 5px 5px var(--accent);
}

.tech-wrapper img {
    max-width: 75px;
    max-height: 75px;
    justify-self: center;
}

.tech-wrapper .light-background {
    background-color: white;
    padding: 0.5rem;
    border-radius: 5px;
}


/* ----- Mobile View ----- */
@media (max-width: 1024px) {
    .tech-stack-section {
        flex-direction: column-reverse;
        gap: 2rem;
    }

    .tech-stack-section .content-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(5, 1fr);
        gap: 5rem;
        margin-top: 5rem;
    }
}