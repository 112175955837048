.about-me-section  {
    position: relative;
    height: fit-content;
    overflow: hidden;
    justify-content: flex-start;
}

.about-me-section.single-page {
    opacity: 0;
    animation: opacityAnimation 2s linear;
    animation-fill-mode: forwards;
}

.about-me-section > .content-wrapper {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    padding-top: 4rem;
    padding-left: 3rem;
}

.about-me-section > .content-wrapper > .content-wrapper {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.about-me-section .content-wrapper a {
    color: var(--accent);
}

.about-me-section .content-wrapper a:hover {
    color: white;
}

.headshot-wrapper {
    width: 30%;
}

.headshot-wrapper > img {
    width: 100%;
    border: 1px solid var(--accent);
    border-radius: 5px;
    box-shadow: 1rem 1rem var(--accent);
    transition: box-shadow 0.25s ease;
}

.headshot-wrapper > img:hover {
    box-shadow: 0.5rem 0.5rem var(--accent);
}


/* ----- Mobile View ----- */
@media (max-width: 767px) {
    .about-me-section > .content-wrapper {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        padding-left: 0;
        text-align: center;
    }

    .about-me-section > .content-wrapper > .content-wrapper {
        width: 100%;
        text-align: left;
    }

    .about-me-section > .content-wrapper > .content-wrapper > h4 {
        text-align: center;
        padding-bottom: 2rem;
    }

    .headshot-wrapper {
        width: 75%;
    }
}